import styled from "styled-components";

export const NEXT = "NEXT";
export const PREV = "PREV";
export const UP = "UP";
export const DOWN = "DOWN";

export const TILEHEIGHT = 334;
export const TILEWIDTH = 220;
export const TILEMARGINHORZ = 10;
export const TILEMARGINVERT = 20;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
`;

export const CarouselSlot = styled.div`
  position: relative;
  z-index: 0;
`;

export const Item = styled.div`
  font-family: "Montserrat", sans-serif;
  color: black;
  text-align: center;
  width: 210px;
  height: 334px;
  margin: 20px 10px;
  padding: 5px;

  background: linear-gradient(161deg, #ffd9f9 7.62%, #c1ffe1 99.03%);
  box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.3), 0px 19px 76px rgba(0, 0, 0, 0.3);
  border-radius: 20px;

  position: relative;
  z-index: 2;

  .icon-div-big {
    padding-top: 39px;
  }

  .icon-div-big svg {
    width: 67px;
    height: 67px;
    fill: url(#WBMIGradient);
  }

  .arrow-icon {
    position: absolute;
    bottom: 50px;
    left: 129px;
  }

  .arrow-icon svg {
    width: 75px;
    height: 75px;
    fill: url(#WBMIGradient);
  }

  .title-text {
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    text-transform: uppercase;
    color: var(--dark-green);
  }

  .top-img-small {
    width: 100%;
    height: 135px;
    margin: auto;
    border-radius: 20px;
    overflow: hidden;
  }

  .top-img-small img {
    width: 100%;
  }

  .top-img-big {
    width: 100%;
    height: 215px;
    border-radius: 20px;
    overflow: hidden;
  }

  .top-img-big img {
    height: 100%;
  }

  .news-title {
    padding-top: 20px;
    font-family: Montserrat;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    width: 170px;
    margin: auto;
    text-align: left;
  }

  .news-subtitle {
    padding-top: 20px;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    width: 170px;
    margin: auto;
    text-align: left;
  }

  .address-div {
    color: var(--dark-green);
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .address-div p {
    padding: 0;
    margin: 0;
  }
`;

export const VertRow = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  transition: ${props => (props.sliding ? "none" : "transform 300ms ease")};
  transform: ${props => {
    if (!props.sliding) {
      return (
        "translateX(calc(50% - " +
        TILEWIDTH / 2 +
        "px - " +
        props.pos * (TILEWIDTH + 2 * TILEMARGINHORZ) +
        "px))"
      );
    }
    if (props.dir === PREV) {
      return (
        "translateX(calc(50% - " +
        TILEWIDTH / 2 +
        "px - " +
        (props.pos + 1) * (TILEWIDTH + 2 * TILEMARGINHORZ) +
        "px))"
      );
    }
    return (
      "translateX(calc(50% - " +
      TILEWIDTH / 2 +
      "px - " +
      (props.pos - 1) * (TILEWIDTH + 2 * TILEMARGINHORZ) +
      "px))"
    );
  }};
`;

export const VertCarouselContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  transition: ${props => (props.sliding ? "none" : "transform 300ms ease")};
  transform: ${props => {
    if (!props.sliding) {
      // return "translateY(calc(75px - " + props.pos * 180 + "px))";

      return (
        "translateY(calc(0px - " +
        props.pos * (TILEHEIGHT + 2 * TILEMARGINVERT) +
        "px))"
      );
    }
    if (props.dir === UP) {
      return (
        "translateY(calc(0px - " +
        (props.pos - 1) * (TILEHEIGHT + 2 * TILEMARGINVERT) +
        "px))"
      );
    }
    return (
      "translateY(calc(0px - " +
      (props.pos + 1) * (TILEHEIGHT + 2 * TILEMARGINVERT) +
      "px))"
    );
  }};

  @media only screen and (min-height: 730px) {
    margin-top: 60px;
  }

  @media only screen and (min-height: 800px) {
    margin-top: 130px;
  }
`;

export const AppContainer = styled.div`
  text-align: right;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 0;

  color: #c1ffe1;
  background: linear-gradient(
    154.81deg,
    rgba(106, 61, 99, 0.76) 9.65%,
    rgba(17, 100, 60, 0.74) 109.61%
  );

  h1 {
    color: #c1ffe1;
    font-family: "Limelight", cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 54px;
    line-height: 54px;

    /* background: -webkit-linear-gradient(#C1FFE1, #FFF); */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */

    margin: 0;
    padding: 0;
  }

  h2 {
    color: #c1ffe1;
    font-family: "Limelight", cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 43px;
    line-height: 43px;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
  }

  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    margin: 0;
  }

  .logo-div {
    position: fixed;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .logo-div img {
    margin: 20px;
    width: 120px;
  }

  .event-info-div {
    margin: 100px 20px 0px 0px;
    /* background: lightyellow; */
  }
`;
