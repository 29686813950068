import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { handleContentArray } from "./assembleArticle";
import { FlamingoTileBlank, GatorTileBlank } from "./team-tiles";
import { ARROWLEFT } from "./images/icons-all";
import gatorAccent from "./images/gator-accent-icon.svg";
import flamingoAccent from "./images/flamingo-accent-icon.svg";

class PlayerTileGator extends Component {
  render() {
    return (
      <GatorTileBlank>
        <StyledPlayerTile>
          <Link to={`/golfer/${this.props.playerID}`}>
            <div className="tile-size">
              <div className="top-img">
                <img
                  src={this.props.playerData.pictureSmall.fields.file.url}
                  alt="Golfer Profile"
                />
              </div>
              <div className="player-name">
                {this.props.playerData.fullName}
              </div>
              <div className="player-nickname">
                "{this.props.playerData.nickname}"
              </div>
            </div>
          </Link>
        </StyledPlayerTile>
        <div className="accent-team-icon">
          <img src={gatorAccent} alt="Alligator Accent Logo" />
        </div>
      </GatorTileBlank>
    );
  }
}

class PlayerTileFlamingo extends Component {
  render() {
    return (
      <FlamingoTileBlank>
        <StyledPlayerTile>
          <Link to={`/golfer/${this.props.playerID}`}>
            <div className="tile-size">
              <div className="top-img">
                <img
                  src={this.props.playerData.pictureSmall.fields.file.url}
                  alt="Golfer Profile"
                />
              </div>
              <div className="player-name">
                {this.props.playerData.fullName}
              </div>
              <div className="player-nickname">
                "{this.props.playerData.nickname}"
              </div>
            </div>
          </Link>
        </StyledPlayerTile>
        <div className="accent-team-icon">
          <img src={flamingoAccent} alt="Bird Accent Logo" />
        </div>
      </FlamingoTileBlank>
    );
  }
}

export { PlayerTileGator, PlayerTileFlamingo };

export const StyledPlayerTile = styled.div`
  a {
    text-decoration: none;
  }

  .top-img {
    position: relative;
    /* min-width: 100%; */
    max-height: 200px;
    margin: auto;
    border-radius: 20px;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .top-img img {
    width: 100%;
    height: auto;
    /* display: block; */
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .player-name {
    padding-top: 20px;
    font-family: Montserrat;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #333333;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .player-nickname {
    padding-top: 5px;
    font-size: 20px;
    font-style: italic;
    line-height: 24px;
    color: #333333;
    width: 100%;
    text-align: center;
  }
`;

class PlayerProfile extends Component {
  constructor(props) {
    super(props);

    let obj = this.findPlayerObj(props.match.params.playerID, props.playerList);
    let color = "linear-gradient(161deg, #ffd9f9 7.62%, #c1ffe1 99.03%)";
    if (!obj) {
      props.history.push("/");
    } else {
      color = this.setBackgroundColor(obj.team2019);
    }

    this.state = {
      playerObj: obj,
      bkgColor: color
    };
  }

  findPlayerObj(id, playerList) {
    let found = false;
    for (let i = 0; i < playerList.length; i++) {
      if (playerList[i].sys.id === id) {
        found = true;
        return playerList[i].fields;
      }
    }
    if (!found) {
      console.log("not found");
      this.props.history.push("/");
    }
  }

  setBackgroundColor(team) {
    if (team === "gator") {
      return "linear-gradient(161deg, #c1ffe1 7.62%, #eafff5 99.03%)";
    }
    return "linear-gradient(157.55deg, #ffd9f9 6.58%, #ffffff 98.88%)";
  }

  render() {
    return (
      <StyledPlayerProfile bkgclr={this.state.bkgColor}>
        <div className="content-container">
          {this.state.playerObj && (
            <div className="content">
              <div className="top-img">
                <div className="top-img-filter"></div>

                <img
                  src={this.state.playerObj.pictureLarge.fields.file.url}
                  alt={this.state.playerObj.pictureLarge.fields.file.title}
                />
                <div className="accent-team-icon">
                  {this.state.playerObj.team2019 === "gator" && (
                    <img src={gatorAccent} alt="Alligator Accent Logo" />
                  )}
                  {this.state.playerObj.team2019 === "flamingo" && (
                    <img src={flamingoAccent} alt="Bird Accent Logo" />
                  )}
                </div>

                {this.state.playerObj.captain && (
                  <div className="captain-text">team captain</div>
                )}
                {this.state.playerObj.assistantCaptain && (
                  <div className="captain-text">assistant captain</div>
                )}
              </div>

              <div className="name-div">{this.state.playerObj.fullName}</div>
              <div className="nickname-div">
                "{this.state.playerObj.nickname}"
              </div>

              {this.state.playerObj.pairing && (
                <div className="addl-info">
                  Paired with: {this.state.playerObj.pairing}
                </div>
              )}
              {this.state.playerObj.teeTime && (
                <div className="addl-info">
                  Tee Time: {this.state.playerObj.teeTime}
                </div>
              )}

              <div className="content-div">
                {handleContentArray(this.state.playerObj.profileText.content)}
              </div>
              <div className="footer">
                <Link to={"/"}>
                  <div>{ARROWLEFT} BACK</div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </StyledPlayerProfile>
    );
  }
}

export { PlayerProfile };

export const StyledPlayerProfile = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 5;
  color: #333333;
  text-align: left;
  overflow: hidden;

  .content-container {
    background: ${props => props.bkgclr || "palevioletred"};
    /* background: linear-gradient(161deg, #ffd9f9 7.62%, #c1ffe1 99.03%); */
    box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.3),
      0px 19px 76px rgba(0, 0, 0, 0.3);
    border-radius: 20px;

    width: 90vw;
    max-width: 600px;
    height: 80vh;
    margin: 10vh auto;
    padding: 5px 5px 20px 5px;
    overflow: hidden;
  }

  .content {
    height: 95%;
    width: 100%;
    overflow-y: scroll;
    border-radius: 20px;
  }

  button {
    outline: none;
    border: none;
    background: rgba(0, 0, 0, 0.5);
    padding: 3px 20px;
    vertical-align: middle;

    color: white;
    font-size: 18px;

    border: 3px solid #ebebeb;
    border-radius: 20px;
  }

  .top-img {
    position: relative;
    width: 100%;
    height: 300px;
    border-radius: 20px;
    overflow: hidden;
  }

  .top-img-filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(182, 182, 182, 0) 0%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }

  .top-img img {
    width: 100%;
  }

  .accent-team-icon {
    position: absolute;
    bottom: 0%;
    left: 20px;
  }

  .captain-text {
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    bottom: 0%;
    right: 20px;
  }

  .date-div {
    margin: 10px;
    font-size: 14px;
  }

  .name-div {
    font-size: 18px;
    line-height: 20px;
    margin: 10px;
  }

  .nickname-div {
    margin: 10px;
    font-size: 20px;
    line-height: 24px;
    font-style: italic;
  }

  .addl-info {
    margin: 3px 20px;
    font-size: 12px;
  }

  .content-div {
    width: 90%;
    /* height: auto; */
    margin: 20px auto;
    font-size: 16px;
    line-height: 24px;
    /* overflow-y: scroll; */
  }

  .footer {
    width: 90%;
    margin: 50px auto 0px auto;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .footer a {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #1b9c5e;
    cursor: pointer;
  }

  .footer svg {
    width: 50px;
    height: 50px;
    vertical-align: middle;
    fill: url(#WBMIGradient);
  }

  @media only screen and (min-width: 600px) {
    .content-container {
      padding: 20px;
    }

    .top-img {
      height: 500px;
    }
  }
`;
