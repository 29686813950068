import React, { Component } from "react";
import Moment from "moment";
import { VertRow, AppContainer } from "./components";
import { Carousel, VertCarousel } from "./Carousel";
import wbmi2019 from "./images/wbmi-2019-web-logo-white.png";
// import wbmiMainLogo from "./images/wbmi-logo.png";

import { BrowserRouter as Router, Route } from "react-router-dom";

import {
  FlamingoTeamTile,
  // FlamingoPlaceholderTile,
  GatorsTeamTile
  // GatorPlaceholderTile
} from "./team-tiles";

import {
  PlayerTileGator,
  PlayerTileFlamingo,
  PlayerProfile
} from "./player-tiles";

import { IconTile, MapTile } from "./icon-tile";
import { TwpwkIconTile, TwpwkInfoTile } from "./twpwk-tiles";
import NewsTile, { ExpandedTile } from "./news-tile";
import HouseTile, {
  ExpdHouseTile,
  ExpdItineraryTile,
  ExpdRulesTile
} from "./house-tiles";

import {
  HOUSE,
  CALENDAR,
  TROPHY,
  COMPASS,
  RELOADING,
  ITUNES,
  GOOGLEPOD,
  TWITTER,
  FACEBOOK
} from "./images/icons-all";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentLoaded: false,
      newsItems: [],
      flamingos: [],
      gators: [],
      allGolfers: [],
      houseItems: [],
      itineraryItems: [],
      rulesItems: [],
      error: false,
      showExpanded: false,
      expendedIndex: 0
    };
  }

  componentDidMount() {
    this.fetchPageContent();
  }

  fetchPageContent = () => {
    const contentful = require("contentful");

    const client = contentful.createClient({
      space: "c3bhh53gce0b",
      environment: "master", // defaults to 'master' if not set
      accessToken: "gsO1ARAeNMFHd2L4Ds6j5EymjnNtqRbJtwDxEUSNtMk"
    });

    client
      .getEntries({
        // content_type: "news"
      })
      .then(
        function(entries) {
          this.sortEntries(entries);
        }.bind(this)
      );
  };

  sortEntries = entries => {
    console.log(entries);

    let newsEntries = [];
    let flamingos = [];
    let gators = [];
    let allGolfers = [];
    let house = [];

    // future
    let itinerary = [];
    let rules = [];

    entries.items.forEach(function(entry) {
      if (entry.sys.contentType.sys.id === "news") {
        // console.log(entry);
        newsEntries.push(entry);
      } else if (entry.sys.contentType.sys.id === "golfer") {
        if (entry.fields.playing2019) {
          if (entry.fields.team2019 === "flamingo") {
            flamingos.push(entry);
            allGolfers.push(entry);
          } else if (entry.fields.team2019 === "gator") {
            gators.push(entry);
            allGolfers.push(entry);
          }
        }
      } else if (entry.sys.contentType.sys.id === "house") {
        house.push(entry);
      } else if (entry.sys.contentType.sys.id === "itinerary") {
        itinerary.push(entry);
      } else if (entry.sys.contentType.sys.id === "rules") {
        rules.push(entry);
      }
    });

    newsEntries.sort(this.sortByDate);
    flamingos.sort(this.sortByCaptain);
    gators.sort(this.sortByCaptain);
    house.sort(this.sortBySortOrder);
    itinerary.sort(this.sortItinerary);
    rules.sort(this.sortRules);

    console.log(itinerary);
    console.log(rules);

    this.setState({
      contentLoaded: true,
      newsItems: newsEntries,
      flamingos: flamingos,
      gators: gators,
      allGolfers: allGolfers,
      houseItems: house,
      itineraryItems: itinerary,
      rulesItems: rules
    });
  };

  sortByDate(a, b) {
    if (Moment(a.fields.date).isBefore(b.fields.date)) return 1;
    if (Moment(b.fields.date).isBefore(a.fields.date)) return -1;
    return 0;
  }

  sortBySortOrder(a, b) {
    return a.fields.sortOrder > b.fields.sortOrder ? 1 : -1;
  }

  sortItinerary(a, b) {
    return a.fields.itineraryOrder > b.fields.itineraryOrder ? 1 : -1;
  }

  sortRules(a, b) {
    return a.fields.rulesOrder > b.fields.rulesOrder ? 1 : -1;
  }

  sortByCaptain(a, b) {
    if (a.fields.captain) return -1;
    if (b.fields.captain) return 1;
    if (a.fields.assistantCaptain) return -1;
    if (b.fields.assistantCaptain) return 1;
    return a.fullName > b.fullName ? -1 : 1;
  }

  showExpanded = index => {
    // this.setState({ expendedIndex: index, showExpanded: true });

    this.props.history.push("/article/1");
  };

  closeExpanded = () => {
    this.setState({ showExpanded: false });
  };

  render() {
    return (
      <div>
        <AppContainer>
          <Router>
            <div className="logo-div">
              <img src={wbmi2019} alt="WBMI Logo" />
            </div>

            <Route
              path="/news/:articleIndex"
              render={props => (
                <ExpandedTile {...props} newsList={this.state.newsItems} />
              )}
            />

            <Route
              path="/house/:articleIndex"
              render={props => (
                <ExpdHouseTile {...props} contentList={this.state.houseItems} />
              )}
            />

            <Route
              path="/itinerary/:articleIndex"
              render={props => (
                <ExpdItineraryTile
                  {...props}
                  contentList={this.state.itineraryItems}
                />
              )}
            />

            <Route
              path="/rules/:articleIndex"
              render={props => (
                <ExpdRulesTile {...props} contentList={this.state.rulesItems} />
              )}
            />

            <Route
              path="/golfer/:playerID"
              render={props => (
                <PlayerProfile {...props} playerList={this.state.allGolfers} />
              )}
            />

            <div className="event-info-div">
              <h1>2019</h1>
              <h2>Florida</h2>
              <h3>September 26-29, 2019</h3>
              <h3>Ponte Vedra Beach, FL</h3>
            </div>

            {!this.state.contentLoaded && (
              <div className="loading-screen">
                <div className="loading-text">Loading</div>
                <div>{RELOADING}</div>
              </div>
            )}

            {this.state.contentLoaded && (
              <VertCarousel>
                <VertRow>
                  <Carousel>
                    {this.state.newsItems.map((fullEntry, i) => (
                      <NewsTile
                        key={fullEntry.sys.id}
                        articleID={fullEntry.sys.id}
                        title={fullEntry.fields.title}
                        subtitle={fullEntry.fields.subtitle}
                        date={fullEntry.fields.date}
                        imgUrl={
                          fullEntry.fields.articlePicture
                            ? fullEntry.fields.articlePicture.fields.file.url
                            : ""
                        }
                        newsIdx={i}
                        urlPrefix="news"
                      />
                    ))}
                  </Carousel>
                </VertRow>

                {Moment().isAfter("2019-09-24") && (
                  <VertRow>
                    <Carousel>
                      <IconTile icon={COMPASS} label="Maps" />

                      <MapTile
                        addr1="The House"
                        addr2=""
                        addr3=""
                        url="https://goo.gl/maps/oaU1UGtEBXSxZ1edA"
                      />

                      <MapTile
                        addr1="Publix"
                        addr2="Super Market"
                        addr3=""
                        url="https://goo.gl/maps/h4JKvUX3xJ759ZNm6"
                      />

                      <MapTile
                        addr1="The Beach"
                        addr2=""
                        addr3=""
                        url="https://goo.gl/maps/UmTZGGF8dHrWAVpv8"
                      />

                      <MapTile
                        addr1="Trader Joe's"
                        addr2=""
                        addr3=""
                        url="https://goo.gl/maps/FnyWybEwcETyKpaQ6"
                      />

                      <MapTile
                        addr1="Palm Valley"
                        addr2="Golf Club"
                        addr3="(Friday Par 3 Course)"
                        url="https://goo.gl/maps/C4FXH3iuyHudDYQe9"
                      />

                      <MapTile
                        addr1="Slammer & Squire"
                        addr2="Golf Course"
                        addr3="(WBMI Course)"
                        url="https://goo.gl/maps/cgcqz5d2nyfvjBfD6"
                      />

                      <MapTile
                        addr1="The Reef"
                        addr2="(restaurant)"
                        addr3=""
                        url="https://goo.gl/maps/cPLvi6C9eJ1RAKdi7"
                      />

                      <MapTile
                        addr1="Cap's On the Water"
                        addr2="(restaurant)"
                        addr3=""
                        url="https://goo.gl/maps/zEHXAHTYwGgb8LvB7"
                      />
                    </Carousel>
                  </VertRow>
                )}

                {Moment().isAfter("2019-08-30") && (
                  <VertRow>
                    <Carousel>
                      <IconTile icon={CALENDAR} label="Itinerary" />
                      {this.state.itineraryItems.map((fullEntry, i) => (
                        <HouseTile
                          key={fullEntry.sys.id}
                          articleID={fullEntry.sys.id}
                          title={fullEntry.fields.day}
                          subtitle={fullEntry.fields.dayTheme}
                          imgUrl={fullEntry.fields.picture.fields.file.url}
                          urlPrefix="itinerary"
                        />
                      ))}
                    </Carousel>
                  </VertRow>
                )}

                {Moment().isAfter("2019-09-18") && (
                  <VertRow>
                    <Carousel>
                      <IconTile icon={TROPHY} label="Rules" />
                      {this.state.rulesItems.map((fullEntry, i) => (
                        <NewsTile
                          key={fullEntry.sys.id}
                          articleID={fullEntry.sys.id}
                          title={fullEntry.fields.ruleTitle}
                          subtitle={fullEntry.fields.ruleSubtitle}
                          imgUrl={fullEntry.fields.rulesPicture.fields.file.url}
                          urlPrefix="rules"
                        />
                      ))}
                    </Carousel>
                  </VertRow>
                )}

                <VertRow>
                  <Carousel>
                    <IconTile icon={HOUSE} label="House" />
                    {this.state.houseItems.map((fullEntry, i) => (
                      <HouseTile
                        key={fullEntry.sys.id}
                        articleID={fullEntry.sys.id}
                        title={fullEntry.fields.title}
                        subtitle={fullEntry.fields.subtitle}
                        imgUrl={fullEntry.fields.picture.fields.file.url}
                        urlPrefix="house"
                      />
                    ))}
                    <MapTile
                      addr1="1193 Neck Road"
                      addr2="Ponte Vedra Beach, FL"
                      addr3="32082"
                      url="https://goo.gl/maps/oaU1UGtEBXSxZ1edA"
                    />
                  </Carousel>
                </VertRow>

                <VertRow>
                  <Carousel>
                    <GatorsTeamTile />
                    {/* <GatorPlaceholderTile /> */}

                    {this.state.gators.map((fullEntry, i) => (
                      <PlayerTileGator
                        key={fullEntry.sys.id}
                        playerID={fullEntry.sys.id}
                        playerData={fullEntry.fields}
                      />
                    ))}
                  </Carousel>
                </VertRow>

                <VertRow>
                  <Carousel>
                    <FlamingoTeamTile />
                    {/* <FlamingoPlaceholderTile /> */}

                    {this.state.flamingos.map((fullEntry, i) => (
                      <PlayerTileFlamingo
                        key={fullEntry.sys.id}
                        playerID={fullEntry.sys.id}
                        playerData={fullEntry.fields}
                      />
                    ))}
                  </Carousel>
                </VertRow>

                <VertRow>
                  <Carousel>
                    <TwpwkIconTile />
                    <TwpwkInfoTile
                      label="iTunes"
                      icon={ITUNES}
                      url="http://bit.ly/7WPWK"
                    />
                    <TwpwkInfoTile
                      label="Google"
                      icon={GOOGLEPOD}
                      url="http://bit.ly/2EWRoWa"
                    />
                    <TwpwkInfoTile
                      label="@twpwk"
                      icon={TWITTER}
                      url="https://twitter.com/twpwk"
                    />
                    <TwpwkInfoTile
                      label=""
                      icon={FACEBOOK}
                      url="https://www.facebook.com/TWPWK"
                    />
                  </Carousel>
                </VertRow>

                {/* <VertRow>
                <Carousel>
                  <IconTile icon={NEWS} label="House" />
                  <Item>1</Item>
                  <Item>2</Item>
                  <Item>3</Item>
                  <Item>4</Item>
                  <Item>5</Item>
                </Carousel>
              </VertRow> */}
              </VertCarousel>
            )}
          </Router>
        </AppContainer>
      </div>
    );
  }
}

export default App;
