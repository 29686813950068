import React from "react";
import styled from "styled-components";

import { ARROWRIGHT } from "./images/icons-all";
import flamingoLogo from "./images/flamingo-main-icon.svg";
import flamingoAccent from "./images/flamingo-accent-icon.svg";
import gatorsLogo from "./images/gator-main-icon.svg";
import gatorAccent from "./images/gator-accent-icon.svg";

const FlamingoTeamTile = props => {
  return (
    <FlamingoTileBlank>
      <StyledFlamingoTeamTile>
        <div className="icon-div-big">
          <img src={flamingoLogo} alt="Flamingos Logo" />
        </div>
        <div className="title-text">Flamingos</div>
        <div className="arrow-icon">{ARROWRIGHT}</div>
        <div className="accent-team-icon">
          <img src={flamingoAccent} alt="Bird Accent Logo" />
        </div>
      </StyledFlamingoTeamTile>
    </FlamingoTileBlank>
  );
};

const FlamingoPlaceholderTile = props => {
  return (
    <FlamingoTileBlank>
      <div className="placeholder-text">
        Players not announced, check back for updates
      </div>
      <div className="accent-team-icon">
        <img src={flamingoAccent} alt="Bird Accent Logo" />
      </div>
    </FlamingoTileBlank>
  );
};

const GatorsTeamTile = props => {
  return (
    <GatorTileBlank>
      <StyledGatorTeamTile>
        <div className="icon-div-big">
          <img src={gatorsLogo} alt="Gators Logo" />
        </div>
        <div className="title-text">Gators</div>
        <div className="arrow-icon">{ARROWRIGHT}</div>
        <div className="accent-team-icon">
          <img src={gatorAccent} alt="Alligator Accent Logo" />
        </div>
      </StyledGatorTeamTile>
    </GatorTileBlank>
  );
};

const GatorPlaceholderTile = props => {
  return (
    <GatorTileBlank>
      <div className="placeholder-text">
        Players not announced, check back for updates
      </div>
      <div className="accent-team-icon">
        <img src={gatorAccent} alt="Alligator Accent Logo" />
      </div>
    </GatorTileBlank>
  );
};

const StyledFlamingoTeamTile = styled.div`
  .icon-div-big {
    padding-top: 27px;
    width: 50%;
    margin-left: 50%;
  }

  .icon-div-big img {
    width: 75px;
    height: 75px;
  }

  .arrow-icon {
    position: absolute;
    bottom: 30px;
    left: 129px;
  }

  .arrow-icon svg {
    width: 75px;
    height: 75px;
    fill: url(#PinkGradient);
  }

  .title-text {
    color: var(--pink);
    font-family: Yellowtail;
    font-style: normal;
    font-weight: normal;
    font-size: 52px;
    line-height: 52px;
    text-align: center;
    width: 100%;
  }
`;

const FlamingoTileBlank = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  width: 210px;
  height: 334px;
  margin: 20px 10px;
  padding: 5px;

  background: linear-gradient(157.55deg, #ffd9f9 6.58%, #ffffff 98.88%);
  box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.3), 0px 19px 76px rgba(0, 0, 0, 0.3);
  border-radius: 20px;

  .accent-team-icon {
    position: absolute;
    bottom: 0px;
    left: 15px;

    width: 50px;
    height: 50px;
  }

  .accent-team-icon img {
    height: 50px;
  }

  .placeholder-text {
    width: 215px;
    height: 209px;
    margin: auto;
    padding-top: 50px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 31px;
    line-height: 38px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ba6e8f;
  }
`;

const StyledGatorTeamTile = styled.div`
  .icon-div-big {
    padding-top: 27px;
    width: 50%;
    margin-left: 50%;
  }

  .icon-div-big img {
    width: 75px;
    height: 75px;
  }

  .arrow-icon {
    position: absolute;
    bottom: 30px;
    left: 129px;
  }

  .arrow-icon svg {
    width: 75px;
    height: 75px;
    fill: url(#GreenGradient);
  }

  .title-text {
    color: var(--green);
    font-family: Mirza;
    font-style: normal;
    font-weight: bold;
    font-size: 67px;
    line-height: 67px;
    text-align: center;
    width: 100%;
  }
`;

const GatorTileBlank = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  width: 210px;
  height: 334px;
  margin: 20px 10px;
  padding: 5px;

  background: linear-gradient(161deg, #c1ffe1 7.62%, #eafff5 99.03%);
  box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.3), 0px 19px 76px rgba(0, 0, 0, 0.3);
  border-radius: 20px;

  .accent-team-icon {
    position: absolute;
    bottom: -0px;
    left: 20px;
    width: 50px;
  }

  .accent-team-icon img {
    width: 75px;
  }

  .placeholder-text {
    width: 215px;
    height: 209px;
    margin: auto;
    padding-top: 50px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 31px;
    line-height: 38px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #1b9c5e;
  }
`;

export {
  FlamingoTeamTile,
  FlamingoPlaceholderTile,
  GatorsTeamTile,
  GatorPlaceholderTile,
  FlamingoTileBlank,
  GatorTileBlank
};
