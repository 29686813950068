import React, { Component } from "react";
import Moment from "moment";
import styled from "styled-components";
import { handleContentArray } from "./assembleArticle";

import { Link } from "react-router-dom";

import { ARROWLEFT } from "./images/icons-all";

class HouseTile extends Component {
  render() {
    return (
      <StyledHouseTile>
        <Link to={`/${this.props.urlPrefix}/${this.props.articleID}`}>
          <div className="tile-size">
            <div className="top-img-big">
              <img src={this.props.imgUrl} alt="House Pict" />
            </div>
            <div className="news-title">{this.props.title}</div>
            <div className="news-subtitle">{this.props.subtitle}</div>
          </div>
        </Link>
      </StyledHouseTile>
    );
  }
}

export default HouseTile;

export const StyledHouseTile = styled.div`
  position: relative;
  font-family: "Montserrat", sans-serif;
  color: black;
  text-align: center;

  a {
    text-decoration: none;
  }

  .tile-size {
    position: relative;
    width: 210px;
    height: 334px;
    padding: 5px;
    margin: 20px 10px;

    background: linear-gradient(161deg, #ffd9f9 7.62%, #c1ffe1 99.03%);
    box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.3),
      0px 19px 76px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    z-index: 2;
  }

  .top-img-small {
    position: relative;
    width: 100%;
    /* margin: auto; */
    border-radius: 20px;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .top-img-small img {
    /* min-width: 100%; */
    height: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .top-img-big {
    width: 100%;
    height: 215px;
    border-radius: 20px;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .top-img-big img {
    height: 100%;
  }

  .news-title {
    padding-top: 10px;
    font-family: Montserrat;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    width: 170px;
    margin: auto;
    text-align: left;
  }

  .news-subtitle {
    padding-top: 10px;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
    width: 170px;
    margin: auto;
    text-align: left;
  }
`;

class ExpdHouseTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIdx: 0
    };

    // console.log(this.props.match.params.articleIndex);
  }

  componentDidMount() {
    let found = false;
    for (let i = 0; i < this.props.contentList.length; i++) {
      if (
        this.props.contentList[i].sys.id ===
        this.props.match.params.articleIndex
      ) {
        this.setState({ currentIdx: i });
        found = true;
      }
    }
    if (!found) {
      console.log("not found");
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <StyledExpandedTile>
        <div className="content-container">
          {this.props.contentList[this.state.currentIdx] && (
            <div className="content">
              <div className="top-img">
                <img
                  src={
                    this.props.contentList[this.state.currentIdx].fields.picture
                      .fields.file.url
                  }
                  alt={
                    this.props.contentList[this.state.currentIdx].fields.picture
                      .fields.file.title
                  }
                />
              </div>
              <div className="title-div">
                {this.props.contentList[this.state.currentIdx].fields.title}
              </div>
              {/* <div className="subtitle-div">{this.props.subtitle}</div> */}
              <div className="content-div">
                {handleContentArray(
                  this.props.contentList[this.state.currentIdx].fields
                    .description.content
                )}
              </div>
              <div className="footer">
                <Link to={"/"}>
                  <div>{ARROWLEFT} BACK</div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </StyledExpandedTile>
    );
  }
}

export { ExpdHouseTile, ExpdItineraryTile, ExpdRulesTile };

export const StyledExpandedTile = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 5;
  color: #333333;
  text-align: left;
  overflow: hidden;

  .content-container {
    background: linear-gradient(161deg, #ffd9f9 7.62%, #c1ffe1 99.03%);
    box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.3),
      0px 19px 76px rgba(0, 0, 0, 0.3);
    border-radius: 20px;

    width: 90vw;
    max-width: 600px;
    height: 80vh;
    margin: 10vh auto;
    padding: 5px 5px 20px 5px;
    overflow: hidden;
  }

  .content {
    height: 95%;
    width: 100%;
    overflow-y: scroll;

    /* background: red; */
  }

  button {
    outline: none;
    border: none;
    background: rgba(0, 0, 0, 0.5);
    padding: 3px 20px;
    vertical-align: middle;

    color: white;
    font-size: 18px;

    border: 3px solid #ebebeb;
    border-radius: 20px;
  }

  .top-img {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
  }

  .top-img img {
    width: 100%;
  }

  .date-div {
    margin: 10px;
    font-size: 14px;
  }

  .title-div {
    font-size: 28px;
    line-height: 32px;
    margin: 10px;
  }

  .subtitle-div {
    margin: 10px;
    font-size: 18px;
    line-height: 24px;
    font-style: italic;
  }

  .content-div {
    width: 90%;
    /* height: auto; */
    margin: 20px auto;
    font-size: 16px;
    line-height: 24px;
    /* overflow-y: scroll; */
  }

  .footer {
    width: 90%;
    margin: 50px auto 0px auto;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .footer a {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #1b9c5e;
    cursor: pointer;
  }

  .footer svg {
    width: 50px;
    height: 50px;
    vertical-align: middle;
    fill: url(#WBMIGradient);
  }

  @media only screen and (min-width: 600px) {
    .content-container {
      padding: 20px;
    }
  }
`;

class ExpdItineraryTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIdx: 0
    };
  }

  componentDidMount() {
    let found = false;
    for (let i = 0; i < this.props.contentList.length; i++) {
      if (
        this.props.contentList[i].sys.id ===
        this.props.match.params.articleIndex
      ) {
        this.setState({ currentIdx: i });
        found = true;
      }
    }
    if (!found) {
      console.log("not found");
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <StyledExpandedTile>
        <div className="content-container">
          {this.props.contentList[this.state.currentIdx] && (
            <div className="content">
              <div className="top-img">
                <img
                  src={
                    this.props.contentList[this.state.currentIdx].fields.picture
                      .fields.file.url
                  }
                  alt={
                    this.props.contentList[this.state.currentIdx].fields.picture
                      .fields.file.title
                  }
                />
              </div>
              <div className="title-div">
                {this.props.contentList[this.state.currentIdx].fields.dayTheme}
              </div>
              <div className="subtitle-div">
                {Moment(
                  this.props.contentList[this.state.currentIdx].fields
                    .dateAndTime
                ).format("dddd MMM DD")}
              </div>
              <div className="content-div">
                {handleContentArray(
                  this.props.contentList[this.state.currentIdx].fields.dayConten
                    .content
                )}
              </div>
              <div className="footer">
                <Link to={"/"}>
                  <div>{ARROWLEFT} BACK</div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </StyledExpandedTile>
    );
  }
}

class ExpdRulesTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIdx: 0
    };
  }

  componentDidMount() {
    let found = false;
    for (let i = 0; i < this.props.contentList.length; i++) {
      if (
        this.props.contentList[i].sys.id ===
        this.props.match.params.articleIndex
      ) {
        this.setState({ currentIdx: i });
        found = true;
      }
    }
    if (!found) {
      console.log("not found");
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <StyledExpandedTile>
        <div className="content-container">
          {this.props.contentList[this.state.currentIdx] && (
            <div className="content">
              <div className="top-img">
                <img
                  src={
                    this.props.contentList[this.state.currentIdx].fields
                      .rulesPicture.fields.file.url
                  }
                  alt={
                    this.props.contentList[this.state.currentIdx].fields
                      .rulesPicture.fields.file.title
                  }
                />
              </div>
              <div className="title-div">
                {this.props.contentList[this.state.currentIdx].fields.ruleTitle}
              </div>
              <div className="subtitle-div">
                {
                  this.props.contentList[this.state.currentIdx].fields
                    .ruleSubtitle
                }
              </div>
              <div className="content-div">
                {handleContentArray(
                  this.props.contentList[this.state.currentIdx].fields.ruleText
                    .content
                )}
              </div>
              <div className="footer">
                <Link to={"/"}>
                  <div>{ARROWLEFT} BACK</div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </StyledExpandedTile>
    );
  }
}
