import React from "react";
import { useSwipeable } from "react-swipeable";
import {
  Wrapper,
  CarouselContainer,
  VertCarouselContainer,
  CarouselSlot,
  UP,
  DOWN,
  PREV,
  NEXT
} from "./components";

const getOrder = ({ index, pos, numItems }) => {
  return index - pos < 0 ? 0 : index - pos;
};
const initialState = { pos: 0, sliding: false, dir: NEXT };

const Carousel = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const numItems = React.Children.count(props.children);
  const slide = dir => {
    if (
      (dir === PREV && state.pos > 0) ||
      (dir === NEXT && state.pos < numItems - 1)
    ) {
      dispatch({ type: dir, numItems });
      setTimeout(() => {
        dispatch({ type: "stopSliding" });
      }, 50);
    }
  };
  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });
  return (
    <div {...handlers}>
      <Wrapper>
        <CarouselContainer
          dir={state.dir}
          sliding={state.sliding}
          pos={state.pos}
        >
          {React.Children.map(props.children, (child, index) => (
            <CarouselSlot
              key={index}
              order={getOrder({ index: index, pos: state.pos, numItems })}
            >
              {child}
            </CarouselSlot>
          ))}
        </CarouselContainer>
      </Wrapper>
    </div>
  );
};

function reducer(state, { type, numItems }) {
  switch (type) {
    case "reset":
      return initialState;
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        pos: state.pos === 0 ? 0 : state.pos - 1
      };
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        pos: state.pos === numItems - 1 ? numItems - 1 : state.pos + 1
      };
    case DOWN:
      return {
        ...state,
        dir: DOWN,
        sliding: true,
        pos: state.pos === 0 ? 0 : state.pos - 1
      };
    case UP:
      return {
        ...state,
        dir: UP,
        sliding: true,
        pos: state.pos === numItems - 1 ? numItems - 1 : state.pos + 1
      };
    case "stopSliding":
      return { ...state, sliding: false };
    default:
      return state;
  }
}

const VertCarousel = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const numItems = React.Children.count(props.children);
  const slide = dir => {
    if (
      (dir === DOWN && state.pos > 0) ||
      (dir === UP && state.pos < numItems - 1)
    ) {
      // console.log(dir);
      dispatch({ type: dir, numItems });
      setTimeout(() => {
        dispatch({ type: "stopSliding" });
      }, 50);
    }
  };
  const handlers = useSwipeable({
    onSwipedUp: () => slide(UP),
    onSwipedDown: () => slide(DOWN),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });
  return (
    <div {...handlers}>
      <Wrapper>
        <VertCarouselContainer
          dir={state.dir}
          sliding={state.sliding}
          pos={state.pos}
        >
          {React.Children.map(props.children, (child, index) => (
            <CarouselSlot
              key={index}
              order={getOrder({ index: index, pos: state.pos, numItems })}
            >
              {child}
            </CarouselSlot>
          ))}
        </VertCarouselContainer>
      </Wrapper>
    </div>
  );
};

export { Carousel, VertCarousel };
