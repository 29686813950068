import React from "react";
import styled from "styled-components";

import { ARROWRIGHT } from "./images/icons-all";
import twpwkLogo from "./images/twpwk-logo.png";

const TwpwkIconTile = props => {
  return (
    <TwpwkTileBlank>
      <div className="twpwk-logo-div">
        <img src={twpwkLogo} alt="TWPWK Logo" />
      </div>
      <div className="arrow-icon">{ARROWRIGHT}</div>
    </TwpwkTileBlank>
  );
};

const TwpwkInfoTile = props => {
  return (
    <TwpwkTileBlank>
      <a className="centered-data" href={props.url}>
        <div className="top-icon">{props.icon}</div>
        <div className="bottom-text">{props.label}</div>
      </a>
    </TwpwkTileBlank>
  );
};

export { TwpwkIconTile, TwpwkInfoTile };

const TwpwkTileBlank = styled.div`
  font-family: "Montserrat", sans-serif;
  color: black;
  text-align: center;
  width: 210px;
  height: 100px;
  margin: 20px 10px;
  padding: 5px;

  background: linear-gradient(161deg, #ffd9f9 7.62%, #c1ffe1 99.03%);
  box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.3), 0px 19px 76px rgba(0, 0, 0, 0.3);
  border-radius: 20px;

  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
    color: #d42b2a;
    cursor: pointer;
  }

  .twpwk-logo-div {
    height: 100px;
    width: 100px;
    margin: 0;
  }

  .arrow-icon {
    padding-right: 10px;
  }

  .arrow-icon svg {
    width: 75px;
    height: 75px;
    fill: url(#RedGradient);
  }

  .centered-data {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bottom-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #d42b2a;
  }
`;
