import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// navigator.serviceWorker.getRegistrations().then(function(registrations) {
//   for (let registration of registrations) {
//     registration.unregister();
//   }
// });

navigator.serviceWorker.getRegistrations().then(function(registrations) {
  for (let registration of registrations) {
    registration
      .unregister()
      .then(function() {
        return window.self.clients.matchAll();
      })
      .then(function(clients) {
        clients.forEach(client => {
          if (client.url && "navigate" in client) {
            client.navigate(client.url);
          }
        });
      });
  }
});
