import React, { Fragment } from "react";

export { handleContentArray };

function handleContentArray(contentArray) {
  // console.log(contentArray);

  if (!contentArray) {
    return;
  }

  let content = [];
  for (let i = 0; i < contentArray.length; i++) {
    switch (contentArray[i].nodeType) {
      case "paragraph":
        content.push(handleParagraph(contentArray[i]));
        break;
      case "text":
        content.push(handleText(contentArray[i]));
        break;
      case "hyperlink":
        content.push(handleHyperlink(contentArray[i]));
        break;
      default:
        break;
    }
  }
  return <Fragment>{content}</Fragment>;
}

function handleParagraph(contentObj) {
  let pText = [];
  for (let i = 0; i < contentObj.content.length; i++) {
    switch (contentObj.content[i].nodeType) {
      case "text":
        pText.push(handleText(contentObj.content[i]));
        break;
      case "hyperlink":
        pText.push(handleHyperlink(contentObj.content[i]));
        break;
      default:
        break;
    }
  }
  return <p>{pText}</p>;
}

function handleHyperlink(contentObj) {
  let linkText = [];
  for (let i = 0; i < contentObj.content.length; i++) {
    linkText.push(handleText(contentObj.content[i]));
  }
  return (
    <a target="_blank" rel="noopener noreferrer" href={contentObj.data.uri}>
      {linkText}
    </a>
  );
}

function handleText(contentObj) {
  if (contentObj.nodeType !== "text") {
    return "";
  }
  let text = contentObj.value;
  if (contentObj.marks.length > 0) {
    switch (contentObj.marks[0].type) {
      case "italic":
        return <i>{text}</i>;
      case "bold":
        return <b>{text}</b>;
      case "underline":
        return <u>{text}</u>;
      default:
        return <span>{text}</span>;
    }
  } else {
    return <span>{text}</span>;
  }
}
