import React from "react";
import { Item } from "./components";
import { ARROWRIGHT } from "./images/icons-all";
import sampleImage from "./images/homebkg.jpg";
import gMapsIcon from "./images/maps-icon.png";

const IconTile = props => {
  return (
    <Item>
      <div className="icon-div-big">{props.icon}</div>
      <div className="title-text">{props.label}</div>
      <div className="arrow-icon">{ARROWRIGHT}</div>
    </Item>
  );
};

const PictureTile = props => {
  return (
    <Item>
      <div className="top-img-big">
        <img src={sampleImage} alt="Gators Logo" />
      </div>
      <div className="news-title">{props.title}</div>
    </Item>
  );
};

const MapTile = props => {
  return (
    <Item>
      <a href={`${props.url}`} target="_blank" rel="noopener noreferrer">
        <img src={gMapsIcon} alt="Google Maps Icon" />
        <div className="address-div">
          <p>{props.addr1}</p>
          <p>{props.addr2}</p>
          <p>{props.addr3}</p>
        </div>
      </a>
    </Item>
  );
};

export { IconTile, PictureTile, MapTile };
